<template>
  <div>
    <b-overlay :show="loading">
      <div>
        <filtros-comisiones
          report-type="descuento"
          @buscar="buscar"
        />
        <b-card>
          <b-card-body>
            <h4 class="card-title">
              Lista Comisiones
            </h4>
            <div>
              <b-row>
                <b-col
                  sm="12"
                  md="6"
                  class="my-1"
                >
                  <div class="d-flex align-items-center mb-1 mb-md-0">
                    <label>Mostrar</label>
                    <b-form-select
                      id="perPageSelect"
                      v-model="perPage"
                      :options="pageOptions"
                      size="sm"
                      class="mx-50 col-md-2"
                      @change="onPerPageChange"
                    />
                    <label>filas por página</label>
                  </div>
                </b-col>
                <b-col md="6">
                  <div class="d-flex justify-content-end text-right">
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="outline-primary"
                      disabled
                    >
                      <feather-icon
                        icon="FilePlusIcon"
                        class="mr-50"
                      />
                      Crear Descuento
                    </b-button>

                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant='outline-primary'
                      class="ml-2"
                      disabled
                      @click="downloadList()"
                    >
                      <feather-icon
                        icon="DownloadIcon"
                        class="mr-50"
                      />
                      Descargar reporte
                    </b-button>
                  </div>
                </b-col>

                <b-col cols="12">
                  <b-table
                    striped
                    hover
                    small
                    :per-page="perPage"
                    :items="items"
                    :fields="fields"
                    responsive
                    class="mb-0"
                    show-empty
                  >
                    <template #cell(desde)="data">
                      {{ data.item.desde }}
                    </template>
                    <template #cell(hasta)="data">
                      {{ data.item.hasta }}
                    </template>
                    <template #cell(usuario)="data">
                      {{ data.item.usuario.nombre_usuario }}
                    </template>
                    <template #cell(grupo)="data">
                      {{ data.item.grupo }}
                    </template>
                    <template #cell(totalVenta)="data">
                      {{ data.item.totalVenta }}
                    </template>
                    <template #cell(totalComision)="data">
                      {{ data.item.totalComision }}
                    </template>
                    <template #cell(descuentoProducto)="data">
                      {{ 'En construccion' }}
                    </template>
                    <template #cell(accion)="row">
                      <!-- Column: Action -->
                      <div class="text-nowrap">
                        <feather-icon
                          v-b-tooltip.hover.v-primary="'Detalle de Comisión'"
                          icon="EyeIcon"
                          class="mr-1"
                          @click="goDetail(row.item)"
                        />
                        <feather-icon v-if="false"
                          v-b-tooltip.hover.v-primary="'Descargar'"
                          icon="DownloadIcon"
                          class="mr-1"
                          variant="flat-primary"
                          @click="confirmar(row.item,'Descargar')"
                        />
                      </div>
                    </template>
                    <template #empty="scope">
                      <div class="text-center">
                        No existen resultados
                      </div>
                    </template>
                  </b-table>
                </b-col>

              </b-row>
              <b-row class="pl-1">
                <b-col
                  cols="8"
                  class="mt-3"
                >
                  <div v-if="totalRows > 0">
                    <span class="text-muted">  Mostrando del {{ from }} al {{ to }} de {{ totalRows }} filas</span>
                  </div>
                </b-col>

                <b-col cols="4">
                  <b-pagination
                    v-model="page"
                    :total-rows="totalRows"
                    :per-page="perPage"
                    align="right"
                    class="mt-3 mr-1"
                    aria-controls="my-table"
                    @change="onPageChange"
                  />
                </b-col>
              </b-row>
            </div>
          </b-card-body>
        </b-card>
      </div>
    </b-overlay>
  </div>
</template>

<script>
import {
  BButton, BButtonToolbar, ButtonGroupPlugin, VBTooltip, BModal, VBModal, BForm, BFormInput, BFormGroup, BTooltip,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
// eslint-disable-next-line import/extensions
import AutocompleteInfinity from '@/components/AutocompleteInfinity'
// eslint-disable-next-line import/extensions
import FiltrosComisiones from '@/components/FiltrosComisiones'

export default {
  name: 'Comisiones',
  components: {
    BButtonToolbar,
    ButtonGroupPlugin,
    BButton,
    BModal,
    BForm,
    BFormInput,
    BFormGroup,
    BTooltip,
    vSelect,
    // eslint-disable-next-line vue/no-unused-components
    AutocompleteInfinity,
    'filtros-comisiones': FiltrosComisiones,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
    'b-modal': VBModal,
  },
  data() {
    return {
      isLoading: true,
      isStriped: true,
      isNarrowed: false,
      isHoverable: true,
      isPaginated: true,
      isPaginationSimple: false,
      isPaginationRounded: false,
      paginationPosition: 'bottom',
      defaultSortDirection: 'asc',
      sortIcon: 'arrow-up',
      pageOptions: [50, 100, 500, 1000],
      sortIconSize: 'is-small',
      sortField: 'usuario',
      sortOrder: 'desc',
      defaultSortOrder: 'desc',
      page: 1,
      perPage: 50,
      totalRows: 0,
      from: 0,
      to: 0,
      selected: new Date(),
      showWeekNumber: false,
      name: '',
      comisionesList: [],
      commissionDetail: [],
      filters: {
        mes: null,
        usuario: null,
        grupo: null,
      },
      date: new Date(),
      minDate: new Date(),
      maxDate: new Date(),
      fields: [
        { key: 'desde', label: 'Desde' },
        { key: 'hasta', label: 'Hasta' },
        { key: 'usuario', label: 'Usuario' },
        { key: 'grupo', label: 'Grupo' },
        { key: 'totalVenta', label: 'Total Ventas' },
        { key: 'totalComision', label: 'Comision' },
        { key: 'descuentoProducto', label: 'Descuento Producto' },
        { key: 'accion', label: 'Acciones', tdClass: 'text-center', thClass: 'text-center' },
      ],
    }
  },
  computed: {
    items() {
      this.totalRows = this.$store.state.comisiones.rowsTotal
      this.comisionesList = this.$store.state.comisiones.comisiones.data
      this.currentPage = this.$store.state.comisiones.currentPage
      this.to = this.$store.state.comisiones.to
      this.from = this.$store.state.comisiones.from
      if (this.comisionesList !== undefined) {
        return this.comisionesList.map(
          item => ({
            desde: item.fecha_inicio,
            hasta: item.fecha_fin,
            usuario: item.usuario,
            grupo: this.decodeGroup(item.usuario.grupos),
            totalVenta: item.total_venta,
            totalComision: item.total_comision,
            id: item.id,
            anticipo: item.total_comision_anticipo,
          }),
        )
      }
      return []
    },
    loading() {
      return  this.$store.state.comisiones.loading
    },
  },
  mounted() {
    this.getData()
  },
  methods: {
    downloadList() {
      const filterData = {
        mes: this.filters.mes,
        usuario: this.filters.usuario,
        grupo: this.filters.grupo,
      }
      this.$store.dispatch('comisiones/getComisionesExcel', filterData).then(response => {
        const blob = new Blob([response.data], { type: 'application/xlsx' })
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = 'excel-listado.xlsx'
        link.click()
      })
    },
    moneyFormat(value) {
      const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      })
      return formatter.format(value)
    },
    decodeGroup(grupos) {
      if (grupos.length > 0) {
        return grupos[0].nombre_grupo_comercial
      }
      return 'N/A'
    },
    buscar(filter) {
      this.filters = filter
      if (this.filters.mes.value !== 'undefined') {
        this.filters.mes = this.filters.mes.value
      } else {
        this.filters.mes = null
      }
      this.page = 1
      this.getData()
    },
    getData() {
      const filterData = {
        page: this.page,
        perPage: this.perPage,
        mes: this.filters.mes,
        usuario: this.filters.usuario,
        grupo: this.filters.grupo,
      }
      this.$store.dispatch('comisiones/getComisiones', filterData)
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    onPageChange(page) {
      this.page = page
      this.getData()
    },
    onPerPageChange(perPage) {
      this.perPage = perPage
      this.getData()
    },
    goDetail(item) {
      this.$store.commit('comisiones/setComisionSeleccionada', item)
      //this.$router.push('/comisiones/detalle/:id')
      this.$router.push({ name: 'detalle_comision', params: { id: item.id } })
    },
  },
}
</script>

<style scoped>

</style>
