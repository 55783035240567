<template>
  <b-card>
    <b-form @submit.prevent>
      <app-collapse>
        <app-collapse-item title="Búsqueda">
          <b-row>
            <b-col md="12">
              <b-form-group

              >
                <h5 class="font-weight-bold">
                  Comisiones
                </h5>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group
              >
                <h5 class="font-weight-bold">
                  Mes
                </h5>
                <div class="form-label-group">
                <!--  <b-field label="Mes">
                    <b-select v-model="filters.mes" placeholder="Seleccione un mes" expanded>
                      <option
                          v-for="option in monthOptions"
                          :value="option.id"
                          :key="option.id">
                        {{ option.name }}
                      </option>
                    </b-select>
                  </b-field> -->
                  <v-select
                      v-model="filters.mes"
                      label="title"
                      placeholder="Seleccione Mes"
                      :options="monthOptions"
                  />
                </div>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group
              >
                <h5 class="font-weight-bold">
                 Usuario
                </h5>
                <div class="form-label-group">
                  <autocomplete-infinity
                      url="comisiones/usuarios-filters"
                      placeholder="Seleccione Usuario"
                      @item-selected="selectUsuario"
                      label="name"
                      id="usuario"
                      :usuario="filters.usuario"
                      itemsPerPage="10"
                  ></autocomplete-infinity>
                </div>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group
              >
                <h5 class="font-weight-bold">
                  Grupo Comercial
                </h5>
                <div class="form-label-group">
                  <autocomplete-infinity
                      url="comisiones/grupos-comerciales-filters"
                      placeholder="Seleccione Grupo Comercial"
                      @item-selected="selectGrupoComercial"
                      label="name"
                      id="grupo"
                      :grupo="filters.grupo"
                      itemsPerPage="10"
                  ></autocomplete-infinity>
                </div>
              </b-form-group>
            </b-col>
            <b-col md="6">

            </b-col>
            <b-col>
              <div class="text-right">
                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    type="submit"
                    v-on:click="$emit('buscar', filters)">

                  <feather-icon
                      icon="SearchIcon"
                      class="mr-50"
                  />
                  <span class="align-middle">Buscar</span>
                </b-button>
                <b-button class="d-none"
                          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                          variant="outline-primary"
                          type="submit">

                  <feather-icon
                      icon="RefreshCcwIcon"
                      class="mr-50"
                  />
                  <span class="align-middle">Limpiar</span>
                </b-button>
              </div>
            </b-col>
          </b-row>
        </app-collapse-item>
      </app-collapse>
    </b-form>
  </b-card>
</template>

<script>
import {
  BRow, BCol, BFormGroup, BFormInput, BFormCheckbox, BForm, BButton, BFormRadio, BFormDatepicker
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import flatPickr from 'vue-flatpickr-component'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import vSelect from 'vue-select'

export default {
  name: 'FiltrosComisiones',
  components: {
    AppCollapse,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
    BFormRadio,
    flatPickr,
    AppCollapseItem,
    BFormDatepicker,
    vSelect,

  },
  directives: {
    Ripple,
  },
  props: ['reportType'],
  data() {
    return {
      filters: {
        mes: null,
        usuario: null,
        grupo: null,
      },
      monthOptions: [
        { value: '1-2021', title: 'Enero - 2021' },
        { value: '2-2021', title: 'Febrero - 2021' },
        { value: '3-2021', title: 'Marzo - 2021' },
        { value: '4-2021', title: 'Abril - 2021' },
        { value: '5-2021', title: 'Mayo - 2021' },
        { value: '6-2021', title: 'Junio - 2021' },
        { value: '7-2021', title: 'Julio - 2021' },
        { value: '8-2021', title: 'Agosto - 2021' },
        { value: '9-2021', title: 'Septiembre - 2021' },
        { value: '10-2021', title: 'Octubre - 2021' },
        { value: '11-2021', title: 'Noviembre - 2021' },
        { value: '12-2021', title: 'Diciembre - 2021' },
      ],
    }
  },
  methods: {
    selectUsuario(usuarios) {
      this.filters.usuario = usuarios != null ? usuarios.id : null;
      console.log('filtro ', this.filters.usuario)
    },
    selectGrupoComercial(gruposComerciales) {
      this.filters.grupo = gruposComerciales != null ? gruposComerciales.id : null;
      console.log('filtro ', this.filters.grupo)
    },

  },
  computed: {
    config() {
      return {
        locale: require(`flatpickr/dist/l10n/es.js`).default['es'],
        dateFormat: "d-m-Y",
        wrap: true,
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.demo-inline-spacing.curso .custom-radio {
  margin-top: 0.5rem !important;
}

.btn-clear {
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #d8d6de;
  border-radius: 0px 3px 3px 0px!important;
  color: #6e6b7b;
}
</style>
